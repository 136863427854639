Mixins.productLightboxes = {
    data: function () {
        return {
            openImage: -1
        }
    },
    methods: {
        createLightboxes: function (images) {
            if (document.body.classList.contains('backoffice') || ! images.length) {
                return false;
            }

            for (let i = 0; i < images.length; i++) {
                let image = images[i];
                image.onclick = () => {
                    let src = image.src;
                    if (image.dataset.large) {
                        src = image.dataset.large;
                    }

                    if (document.getElementsByClassName('backdrop').length == 0) {
                        let backdrop = document.createElement('div');

                        backdrop.setAttribute("id", "closeBackdrop");
                        backdrop.classList.add('backdrop');
                        backdrop.classList.add('backdrop--visible');
                        document.body.appendChild(backdrop);

                        let btn = document.createElement("BUTTON");
                        btn.setAttribute("id", "imgClose");
                        btn.innerHTML = "&times;";
                        document.body.appendChild(btn);

                        let leftbtn = document.createElement("BUTTON");
                        leftbtn.setAttribute("id", "imgLeft");
                        document.body.appendChild(leftbtn);

                        let rightbtn = document.createElement("BUTTON");
                        rightbtn.setAttribute("id", "imgRight");
                        document.body.appendChild(rightbtn);


                    }

                    basicLightbox.create('<img width="auto" height="100%" src="' + src + '" />', {
                        onShow: (instance) => {

                            this.openImage = i;
                        },
                        onClose: (instance) => {
                            if (this.openImage === i) {
                                this.openImage = -1;
                            }

                            this.closeBackdrop();
                        }
                    }).show();

                }
            }
            document.addEventListener("keyup", this.handleKeyUp);
            document.addEventListener("click", this.handleClick);
        },
        handleClick: function(e) {
            let that = this;
            let i = that.openImage;
            let images = this.images;
            let iterator = -1;
            let keyCode = e.keyCode;

            if(document.getElementById('imgClose')){
                document.getElementById('imgClose').onclick = function(){

                    let backdrop = document.querySelector('.backdrop');

                    that.closeLightbox();
                    that.closeBackdrop();

                    var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

                    if(isIE11) {
                        setTimeout(function () {
                            backdrop.outerHTML = "";
                            btn.outerHTML = "";

                        }, 410);
                    }
                }
            }
            if(document.getElementById('imgLeft')) {
                document.getElementById('imgLeft').onclick = function () {
                    // left
                    iterator = i - 1;
                    if (iterator == -1) {
                        // naar laatste
                        iterator = images.length - 1;
                    }
                    that.closeLightbox();
                    images[iterator].click();
                }
            }
            if(document.getElementById('imgRight')) {
                document.getElementById('imgRight').onclick = function () {
                    // right
                    iterator = i + 1;

                    if (iterator == images.length) {
                        // terug naar eerste
                        iterator = 0;
                    }
                    that.closeLightbox();
                    images[iterator].click();


                }
            }

        },
        handleKeyUp: function(e) {

            if(this.openImage >= 0) {
                let i = this.openImage;
                let images = this.images;
                let iterator = -1;
                let keyCode = e.keyCode;


                // eerst kijken of het esc is
                if(keyCode == 27) {
                    // escape / sluiten
                    this.closeLightbox();
                    this.closeBackdrop();
                }

                // onderstaande deel alleen bij meerdere afbeeldingen uitvoeren
                if (typeof images == "undefined") {
                    return false;
                }

                if(keyCode == 39) {
                    // right
                    iterator = i + 1;

                    if (iterator == images.length) {
                        // terug naar eerste
                        iterator = 0;
                    }
                } else if(keyCode == 37) {
                    // left
                    iterator = i - 1;

                    if (iterator == -1) {
                        // naar laatste
                        iterator = images.length - 1;
                    }
                }

                if (images[iterator]) {
                    this.closeLightbox();

                    images[iterator].click();
                }
            }

            return true;
        },

        closeLightbox: function () {
            let boxes = document.querySelectorAll('.basicLightbox');
            boxes.forEach(function (box) {
                box.classList.remove('basicLightbox--visible');

                setTimeout(function () {
                    box.remove();
                }, 410);
            });
        },

        closeBackdrop: function () {
            let backdrop = document.querySelector('.backdrop');
            backdrop.classList.remove('backdrop--visible');


            var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

            if(isIE11) {
                setTimeout(function () {
                    backdrop.outerHTML = "";
                    btn.outerHTML = "";

                }, 410);
            } else {
                setTimeout(function () {
                    backdrop.remove();
                    document.getElementById('imgClose').remove();
                    document.getElementById('imgLeft').remove();
                    document.getElementById('imgRight').remove();

                }, 410);
            }
        }
    }
};
