Vue.component("navigation-component", {
    name: 'navigation-component',
    data() {
        return {
            activeSubLevel: 0
        }
    },
    props: [
        'menuExpanded',
        'isPaginated',
        'hasTopbar',
        'topbarVisible',
    ],
    watch: {
        menuExpanded: function (newValue) {
            if (newValue) {
                // disableBodyScroll(this.$refs.scrollLock);
            } else {
                // enableBodyScroll(this.$refs.scrollLock);
            }
        }
    },
    mounted() {
        // clearAllBodyScrollLocks();
    },
    methods: {
        closeMenu() {
            this.$emit('close-menu');
            this.activeSubLevel = 0;
        },
        incrementActiveSubLevel() {
            this.activeSubLevel++;
        },
        decrementActiveSubLevel() {
            this.activeSubLevel--;
        },
    }
});
