Vue.component("priority-navigation", {
    name: 'priority-navigation',
    props: [
        'scrolled',
    ],
    data() {
        return {
            scrolledToLeft: true,
            scrolledToRight: true,
        }
    },
    mounted() {
        this.onScroll();
        this.$refs.priorityNavigationList.onscroll = () => {
            this.onScroll()
        };
    },
    methods: {
        onScroll() {
            let priorityNavigationList = this.$refs.priorityNavigationList;

            if (priorityNavigationList.scrollLeft <= 1) {
                this.scrolledToLeft = true;
            } else {
                this.scrolledToLeft = false;
            }

            if (priorityNavigationList.scrollWidth - priorityNavigationList.offsetWidth - priorityNavigationList.scrollLeft <= 1) {
                this.scrolledToRight = true;
            } else {
                this.scrolledToRight = false;
            }
        }
    },
});
