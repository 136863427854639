Vue.component("header-component", {
    name: 'header-component',
    props: [
        'hasTopbar',
    ],
    data: function () {
        return {
            // Default properties
            menuExpanded: false,
            showTopBar: true,
            scrolled: false,
            oldScrollPos: 0,
            scrollMargin: 20,
        }
    },
    computed: {},
    mounted() {
        let that = this;
        window.addEventListener('scroll', function () {
            that.watchScrollPosition();
        });

        this.watchScrollPosition();
    },
    methods: {
        watchScrollPosition: function () {
            let that = this;

            const scrollTop = window.pageYOffset;

            if (scrollTop > that.scrollMargin) {
                that.scrolled = true;
                that.showTopBar = false;
            } else {
                that.scrolled = false;
                that.showTopBar = true;
            }

            if (scrollTop > (that.oldScrollPos + that.scrollMargin)) {
                that.showTopBar = false;
            }
            if (scrollTop < that.oldScrollPos || that.oldScrollPos == 0 || scrollTop == 0) {
                that.showTopBar = true;
            }

            that.oldScrollPos = scrollTop;
        },
    },
    watch: {},
});
