Vue.component("navigation-item", {
    name: 'navigation-item',
    data() {
        return {
            isActive: false,
        }
    },
    props: [
        'hasSub',
        'isPaginated',
        'subLevel',
        'activeSubLevel',
    ],
    mixins: [
    ],
    watch: {
        activeSubLevel(val) {
            if (val < this.subLevel) this.isActive = false;
        }
    },
    methods: {
        navigationHandler(e) {
            if (this.hasSub && this.isPaginated) {
                e.preventDefault();
                e.stopPropagation();
                this.isActive = true;
                this.localActiveSubLevel++;
                this.incrementActiveSubLevel();
            }
        },
        incrementActiveSubLevel() {
            this.localActiveSubLevel--;
            this.$emit('increment-active-sub-level')
        }
    }
});

