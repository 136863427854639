Vue.component("product-slider", {
    data: function () {
        return {
            flkty: null
        }
    },
    computed: {
        images: function () {
            return this.$refs.productDetailSlider.querySelectorAll('.product-slider__slide');
        }
    },
    mounted: function () {
        this.initProductslider();
        this.initProductsliderNav();

    },
    mixins: [Mixins.productLightboxes],
    methods: {
        initProductslider() {
            let flkty = new Flickity(this.$refs.productDetailSlider, {
                autoPlay: false,
                pageDots: false,
                //wrapAround: this.images.length > 3,
                bgLazyLoad: true,
                draggable: ('ontouchstart' in window)
            });

            this.createLightboxes(this.images);
        },

        initProductsliderNav() {
            let flkty = new Flickity(this.$refs.productDetailSliderNav, {
                pageDots: false,
                prevNextButtons: false,
                contain: true,
                //wrapAround: this.images.length > 3,
                cellAlign: this.images.length > 3 ? 'center' : 'left',
                asNavFor: this.$refs.productDetailSlider,
                draggable: ('ontouchstart' in window)
            });
        }
    },
});
